import { User } from "@/model/user.model";
import { Host } from "@/model/host.model";
import { HostRole } from "@/model/types";

export enum UserInfoMode {
    VIEW = "VIEW",
    EDIT = "EDIT",
}

export type RawHostMembership = {
    id?: string | null;
    roles?: HostRole[] | null;
    host?: Host | null;
}

export type RawUser = Omit<User, "hostMemberships"> & {
    hostMemberships: RawHostMembership[];
}

export type UserOptions = {
    disableAddDeleteHostMemberships?: boolean;
}
