import { User } from "@/model/user.model";
import { store } from "@/main";
import { ObjectService } from "@/services/object.service";
import { Nullable } from "@/model/types";

export class UserService {
  static async updateUser(editedUser: User, user: Nullable<User>, userStoreName?: string): Promise<void> {
    if (ObjectService.isEqual(editedUser, user)) {
      throw Error("User has not updated!");
    }

    const currentUser = store.state.userModule.user as User;

    if (editedUser.id !== currentUser.id && !userStoreName) {
      throw Error("To update user you need to provide storeModule href!");
    }

    let updatedUser = editedUser;

    if (!ObjectService.isEqual({ ...updatedUser, hostMemberships: [] }, { ...user, hostMemberships: [] })) {
      updatedUser = updatedUser.id && updatedUser.id === currentUser.id
        ? await store.dispatch("userModule/updateCurrentUser", updatedUser)
        : await store.dispatch(`${userStoreName}/addOrSaveUser`, updatedUser);
    }

    updatedUser.hostMemberships = editedUser.hostMemberships;

    if (!ObjectService.isEqual(updatedUser.hostMemberships, user.hostMemberships)) {
      if (!updatedUser.id) {
        throw Error("Unknown user to update host memberships");
      }

      const newHostMemberships = updatedUser.hostMemberships.filter(it => !it.id);

      const updatedHostMemberships = updatedUser.hostMemberships.filter(updatedHostMembership => {
        const hostMembership = updatedHostMembership.id && user.hostMemberships?.find(it => (
          it.id === updatedHostMembership.id
        ));

        const updatedHostMembershipValues = updatedHostMembership.roles.map(it => it.value);
        const hostMembershipValues = hostMembership ? hostMembership.roles.map(it => it.value) : [];

        return hostMembership && (
          !updatedHostMembershipValues.every(it => hostMembershipValues.includes(it))
            || !hostMembershipValues.every(it => updatedHostMembershipValues.includes(it))
        );
      });

      const deletedHostMemberships = user.hostMemberships?.filter(it => (
        !updatedUser.hostMemberships.some(item => item.id === it.id)
      )) || [];

      await Promise.all([
        ...newHostMemberships.map(it => store.dispatch("hostUsersModule/addHostMembership", { hostMembership: it, user: updatedUser })),
        ...updatedHostMemberships.map(it => store.dispatch("hostUsersModule/updateHostMembership", { hostMembership: it })),
        ...deletedHostMemberships.map(it => store.dispatch("hostUsersModule/deleteHostMembership", { hostMembership: it })),
      ]);

      if (updatedUser.id === currentUser.id) {
        await store.dispatch("userModule/loadUserData");
      }
    }
  }
}
