
import {
  Component, Emit, Prop, Ref, Vue, Watch,
} from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { debounce } from "lodash";
import { getHostModuleNamespace, getUserModuleNamespace } from "@/store/store.utils";
import { USER_HOST_ROLES } from "@/utils/user-utils";
import { Host } from "@/model/host.model";
import { HostRole } from "@/model/types";
import { User } from "@/model/user.model";
import { RawHostMembership } from "@/components/user/user-info-types";
import { HostMembership } from "@/model/host-membership.model";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

const debounceCb = debounce((cb: () => Promise<void>) => {
  cb();
}, 300);

@Component({})
export default class UserHostMembership extends Vue {
  @Prop()
  pRoles!: HostRole[] | null;

  @Prop()
  pHost!: Host | null;

  @Prop()
  pSelectedMemberships!: RawHostMembership[];

  @Prop()
  pExistsMemberships!: HostMembership[];

  @Prop()
  pDisableHost!: boolean;

  @Prop()
  pDisabled!: boolean;

  @Prop()
  pLoading!: boolean;

  @Prop()
  pIsViewMode!: boolean;

  @Ref("autocompleteComponent")
  autocompleteComponent: any;

  @State("user", getUserModuleNamespace())
  user!: User;

  selectedRoles: HostRole[] = this.pRoles ? [...this.pRoles] : [];

  host: Host | null = this.pHost ? { ...this.pHost } as Host : null;

  loading = false;

  allHostRoles = USER_HOST_ROLES;

  matchedHosts: { text: string; value: Host }[] = this.host ? [{
    text: this.host.name,
    value: this.host,
  }] : [];

  rolesUpdated() {
    this.emitData();
  }

  @Watch("host")
  hostUpdated() {
    this.emitData();
  }

  @Emit("update")
  emitData() {
    return {
      id: this.pExistsMemberships.find(it => it.host.id === this.host?.id)?.id,
      roles: this.selectedRoles,
      host: this.host,
    };
  }

  @Action("loadFilteredHost", getHostModuleNamespace())
  loadHost!: (data: { name: string }) => Promise<Host[]>

  async loadFilteredHosts(event: any) {
    if ((event.key.length != 1 && event.key != "Backspace") || !this.autocompleteComponent) return;

    this.loading = true;

    debounceCb(async () => {
      if (!this.autocompleteComponent) return;

      try {
        const hosts = await this.loadHost({ name: this.autocompleteComponent.internalSearch });

        this.matchedHosts = hosts.map((it) => ({
          text: it.name,
          value: it,
          disabled: !this.isAllowedHost(it),
        }));
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }

      this.loading = false;
    });
  }

  isAllowedHost(host: Host) {
    return (this.user.isSuperAdmin || this.user.isUserAdminHost(host))
        && !this.pSelectedMemberships.filter(it => it.host && it.roles?.length).some(it => it.host?.id === host.id);
  }
}
