
import {
  Component, Emit, Prop, Ref, Vue, Watch,
} from "vue-property-decorator";
import { State } from "vuex-class";
import { User } from "@/model/user.model";
import { USER_TYPES_DATA } from "@/utils/utils";
import { getUserModuleNamespace } from "@/store/store.utils";
import UserHostMembership from "@/components/user/UserHostMembership.vue";
import { HostMembership } from "@/model/host-membership.model";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { USER_HOST_GUEST_ROLE } from "@/utils/user-utils";
import {
  RawHostMembership, RawUser, UserInfoMode, UserOptions,
} from "./user-info-types";
import { ObjectService } from "@/services/object.service";
import EmailTextField from "@/components/custom-components/EmailTextField.vue";

@Component({
  components: {
    EmailTextField,
    ConfirmationDialog,
    UserHostMembership,
  },
})
export default class UserInfo extends Vue {
  @Prop({ default: "" })
  pTitle!: string;

  @Prop()
  pUser!: User | undefined;

  @Prop({ default: UserInfoMode.EDIT })
  pMode!: UserInfoMode;

  @Prop({ default: () => ({}) })
  pOptions!: UserOptions;

  @Prop()
  pLoading!: boolean;

  @State("user", getUserModuleNamespace())
  user!: User;

  @Ref()
  confirmationDialog: any;

  emailIsValid = !!this.pUser?.email;

  targetUser = (this.pUser ? {
    ...this.pUser,
    hostMemberships: this.pUser?.hostMemberships ? [...this.pUser.hostMemberships] : [],
  } : { hostMemberships: [] }) as unknown as RawUser;

  userTypes = USER_TYPES_DATA;

  @Watch("isDirty")
  @Watch("targetUser.firstName")
  @Watch("targetUser.lastName")
  @Watch("targetUser.email")
  @Watch("targetUser.type")
  @Watch("targetUser.hostMemberships")
  targetUserUpdated() {
    this.emitOnUpdate();
  }

  @Emit("update")
  emitOnUpdate() {
    return {
      user: this.targetUser,
      isDirty: this.isDirty,
      isValid: this.isValid,
    };
  }

  get isValid() {
    return this.targetUser.firstName
      && this.targetUser.lastName
      && this.targetUser.email
      && this.emailIsValid
      && this.targetUser.type
      && this.targetUser.hostMemberships.every((it) => it.host && it.roles?.length);
  }

  get isDirty() {
    return !ObjectService.isEqual(
      { ...this.pUser },
      { ...this.targetUser, hostMemberships: this.targetUser.hostMemberships.filter((it) => it.host && it.roles?.length) },
    );
  }

  get isViewMode() {
    return this.pMode === UserInfoMode.VIEW;
  }

  get disableEditing() {
    return this.targetUser.id
        && !this.isMyProfile
        && !this.user.isSuperAdmin;
  }

  get isMyProfile() {
    return this.user.id === this.targetUser.id;
  }

  get showAddMembershipBtn() {
    return this.user.isSuperAdmin
      && !this.isViewMode
      && this.targetUser.hostMemberships.every((it) => it.host && it.roles?.length);
  }

  get title() {
    if (this.isMyProfile) return "My Profile";

    return this.targetUser.id ? "Edit Profile" : "Create Profile";
  }

  isEditableMembership(membership: RawHostMembership) {
    return ((!membership.host || !membership.roles?.length)
        || this.user.isUserAdminHost(membership.host)
        || this.user.isSuperAdmin)
        && !this.isViewMode;
  }

  addMembership() {
    this.targetUser.hostMemberships.push({
      roles: [USER_HOST_GUEST_ROLE],
      host: null,
    });
  }

  updateMembership(membership: HostMembership, i: number) {
    const tmp = [...this.targetUser.hostMemberships];
    this.targetUser.hostMemberships = [];
    tmp[i] = membership;
    this.targetUser.hostMemberships = [...tmp];
  }

  removeMembershipWithConfirmation(index: number) {
    const membership = this.targetUser.hostMemberships[index];

    if (!membership.roles || !membership.host || !membership.id) {
      this.removeMembership(index);
      return;
    }

    this.confirmationDialog.openDialog({
      header: "Removing membership",
      text: `Are you sure you want to remove ${membership.roles.map(it => it.text).join(", ")} membership for ${this.targetUser.firstName} ${this.targetUser.lastName} of "${membership.host.name}"?`,
      submitText: "Remove",
      submitColor: "red",
      onSubmit: async () => {
        this.removeMembership(index);
      },
    });
  }

  removeMembership(index: number) {
    const tmp = [...this.targetUser.hostMemberships];
    this.targetUser.hostMemberships = [];
    // future to rebuild memberships fields..
    Vue.nextTick(() => {
      tmp.splice(index, 1);
      this.targetUser.hostMemberships = [...tmp];
    });
  }

  mounted() {
    if (!this.targetUser.hostMemberships.length && this.pOptions.disableAddDeleteHostMemberships) {
      throw Error("Failed logic! You should not disable Add Delete HostMemberships functionality IF user has no host memberships!");
    }
  }
}
